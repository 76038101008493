import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useTable, useFilters, usePagination } from "react-table";
import "../../table/FilteringTable/filtering.css";
import axiosInstance from "../../../../services/AxiosInstance";
import { getDefaultParams } from "../../../../services/DefaultParamets";
import Tables from "jsx/components/CitoPlus/components/Tables";
import { CurrencyComponent } from "../components/CurrencyComponnet";
import DatePickerSingle from "../components/DatePickerSingle";

import { formatDate } from "../utils";
import FacturaParking from "./FacturaPark";
import { ButtonGroup } from "react-bootstrap";
import {
  CitoPlusLinkIconPrimary,
  LinkPrimary,
} from "../components/CitoPlusLink";
import {
  BotonPrimary,
  BotonPrimaryText,
  BotonDangerText,
} from "../components/BotonesCustom";
import { notifyError, notifySuccess } from "../components/CitoPlusToastr";
import Comentarios from "jsx/components/CitoPlus/components/sidebar_comments";

import ModalCambiarTextoFactura from "./ModalCambiarTextoFactura";

export const FacturacionVehicular = () => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const [dataPaginated, setDataPaginated] = useState({
    current_page: "",
    total_pages: "",
    next: "",
    previous: "",
    first_page: "",
    last_page: "",
    count: "",
  });

  // Totales del recuado
  const [sumaTotalRecaudo, setSumaTotalRecaudo] = useState(0);
  const [sumaTotalGraciaRecaudo, setSumaTotalGraciaRecaudo] = useState(0);

  // Dates and filters del recaudo
  const [startDateStart, setDateRangeStart] = useState(null);
  const [startDateEnd, setDateRangeEnd] = useState(null);

  // Modal Facturacion
  const [showModalFacturacion, setShowModalFacturacion] = useState(false);
  const [showModalFacturacionSlug, setShowModalFacturacionSlug] =
    useState(null);
  const [showModalReciboUrl, setShowModalReciboUrl] = useState(null);
  const [showModalReciboData, setShowModalReciboData] = useState({});

  const URL = "/api/v3/parking/recaudo/";
  const URL_TOTALES = `${URL}totales/`;
  const URL_EXPORT = `${URL}export/`;
  const URL_NOTAS = "/api/v3/vigilancia/visitantes-comentarios/";

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterParams, setFilterParams] = useState({
    search: "",
    h_ingreso_after: "",
    h_ingreso_before: "",
    h_salida_after: "",
    h_salida_before: "",
  });

  const columns = useMemo(
    () => [
      {
        id: "consecutivo",
        Header: "# ",
        disableFilters: true,
        disableSortable: false,
        accessor: "consecutivo",
      },
      {
        id: "h_ingreso",
        Header: "Ingreso ",
        disableFilters: true,
        disableSortable: false,
        accessor: "h_ingreso",
      },
      {
        id: "h_salida",
        Header: "Salida ",
        disableFilters: true,
        disableSortable: false,
        accessor: "h_salida",
      },
      {
        Header: "Unidad ",
        accessor: "unidad_visita",
        disableFilters: true,
        disableSortable: true,
        accessor: "unidad_visita",
      },
      {
        Header: "Vehículo ",
        accessor: "vehiculo",
        disableFilters: true,
        disableSortable: true,
        accessor: "vehiculo",
      },
      {
        Header: "Tarifas",
        disableFilters: true,
        disableSortable: true,
        accessor: (row) => {
          return (
            <>
              <div className="right-align-cell">
                <p className="paragraph-left">Gracia: {row.total_gracia}</p>
                <p className="paragraph-left">
                  Total: {row.total.toLocaleString("es-ES")}
                </p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Acciones",
        disableFilters: true,
        disableSortable: true,
        accessor: (row) => {
          return (
            <>
              <div className="form-group">
                <ButtonGroup aria-label="Botones de acciones visitantes">
                  <CitoPlusLinkIconPrimary
                    title="Ver facturación"
                    to={`/facturacion-vehicular/${row.slug}/`}
                  >
                    <i className="fas fa-eye"></i>
                  </CitoPlusLinkIconPrimary>
                  <CitoPlusLinkIconPrimary title="Comentarios">
                    <i className="fas fa-comments"></i>
                  </CitoPlusLinkIconPrimary>

                  <CitoPlusLinkIconPrimary
                    title="Imprimir"
                    onClick={() => {
                      setShowModalReciboUrl(
                        `${URL}${row.slug}/detalle_factura_en_progreso/`
                      );
                      setShowModalFacturacion(true);
                    }}
                  >
                    <i className="fas fa-print"></i>
                  </CitoPlusLinkIconPrimary>

                  <CitoPlusLinkIconPrimary
                    href="#"
                    onClick={() => {
                      handleComentariosClick(row.comentarios, row.slug);
                    }}
                  >
                    <i className="fas fa-comments"></i>
                    {row.total_comentarios > 0 && (
                      <span
                        className="badge bg-light rounded-circle position-absolute top-0 start-100 translate-middle"
                        style={{ zIndex: 1, color: "black", fontSize: "12px" }}
                      >
                        {row.total_comentarios}
                      </span>
                    )}
                  </CitoPlusLinkIconPrimary>
                </ButtonGroup>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  useEffect(() => {
    if (filterApplied) {
      fetchUrl(1);
      setFilterApplied(false);
    }
  }, [filterParams, filterApplied]);

  const fetchUrl = async ({ pageIndex = 1, search = "", ordering = [], url = "" }) => {
    defaultParams.page = pageIndex;

    if (searchValue !== "") {
      defaultParams.search = searchValue;
      search = searchValue;
    } else {
      defaultParams.search = search;
    }

    // Cargar totales
    try {
      const response = await axiosInstance.get(URL_TOTALES, {
        params: {
          ...filterParams,
        },
      });
      setSumaTotalRecaudo(response.data.total);
      setSumaTotalGraciaRecaudo(response.data.total_tiempo_gracia);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(true);
    try {
      var response;
      if (url === "") {
        if (pageIndex < 1 || pageIndex > totalPages) {
          return;
        }
        response = await axiosInstance.get(URL, {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering,
          },
        });
      } else {
        response = await axiosInstance.get(url);
      }

      setDataPaginated({
        ...((({ current_page, total_pages, next, previous, first_page, last_page, count }) => ({
          current_page,
          total_pages,
          next,
          previous,
          first_page,
          last_page,
          count,
        }))(response.data)),
      });
      setData(response.data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 404) {
        console.log("Página no encontrada");
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = () => {
    setFilterApplied(true);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log("Presionó Enter");
  };

  const handleClearClick = () => {
    handleSearchChange("");
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder =
      sortField === id ? (order === "asc" ? "desc" : "asc") : "asc";

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl({pageIndex: 1, search: searchValue, ordering: `${newOrder === "desc" ? "-" : ""}${id}`});
  };

  const handleExport = async () => {
    try {
      const response = await axiosInstance.get(URL_EXPORT, {
        params: {
          ...filterParams,
        },
      });
      if (response.status === 200) {
        notifySuccess(
          "Se comenzo tarea de exportacion, por favor espere, este proceso puede tardar algunos minutos."
        );
      }
    } catch (error) {
      notifyError("Error al iniciar proceso de exportacion.");
    }
  };

  // Minuta Cambiar Text Factura
  const [showModalCambiarTextoFactrura, setShowModalCambiarTextoFactrura] =
    useState(false);
  const handleShowCambiarTextoFactrura = function () {
    setShowModalCambiarTextoFactrura(true);
  };
  const handleCloseCambiarTextoFactrura = () =>
    setShowModalCambiarTextoFactrura(false);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  // Comentarios
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});
  const [selectedPrePostData, setSelectedPrePostData] = useState({});

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      visitante: slug,
      nota: "",
    });

    setUrlComentariosParams({
      visitante__slug: slug,
      page_size: 100,
    });
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  };

  const handleNewComment = (e) => {
    fetchUrl(1, searchValue, "");
  };
  // FIN COMENTARIOS

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="fs-20 mb-0 me-auto">Recaudo vehicular</h4>
        {/* <BotonDangerText
          title="Facturación"
          type="button"
          className='me-auto'
          onClick={() => {

          }}>
          Facturación electrónica
        </BotonDangerText> */}
        <LinkPrimary to={"#"} onClick={handleShowCambiarTextoFactrura}>
          <i className="fas fa-file-excel me-2"></i>Cambiar texto factura
        </LinkPrimary>
      </div>

      <div className="col-xl-12 col-lg-12 col-sm-12">
        <div className="card">
          <div className="card-headers pt-0 pb-0 text-center">
            <div className="row">
              <div className="col-4 pt-3 pb-3 border-end">
                <h3 className="mb-1 text-primary">{sumaTotalGraciaRecaudo}</h3>
                <span>Total Tiempo Gracia</span>
              </div>
              <div className="col-4 pt-3 pb-3 border-end">
                <h3 className="mb-1 text-primary">
                  <CurrencyComponent amount={sumaTotalRecaudo} />
                </h3>
                <span>Total Recaudo</span>
              </div>
              <div className="col-4 pt-3 pb-3">
                <h3 className="mb-1 text-primary">{totalRecords}</h3>
                <span>Total Registros</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <div className="row align-items-center">
              <div className="input-group input-group-sm row">
                <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                  <div className="px-2">
                    <input
                      type="text"
                      value={filterParams.search}
                      name="search"
                      id="search"
                      maxLength="100"
                      className="form-control form-control-sm rounded w-full h-50 align-items-center"
                      placeholder="Buscar por consecutivo, inmueble, placa y valor..."
                      onChange={(update) => {
                        let search = update.target.value;
                        setFilterParams({
                          ...filterParams,
                          search: search,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                  <DatePickerSingle
                    className="p-0"
                    selectedDate={startDateStart}
                    placeholderText="Fecha de Ingreso"
                    onChange={(date) => {
                      setDateRangeStart(date);
                      setFilterParams({
                        ...filterParams,
                        h_ingreso: date ? formatDate(date) : "",
                      });
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                  <DatePickerSingle
                    selectedDate={startDateEnd}
                    placeholderText="Fecha de Salida"
                    onChange={(date) => {
                      setDateRangeEnd(date);
                      setFilterParams({
                        ...filterParams,
                        h_salida: date ? formatDate(date) : "",
                      });
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                  <ButtonGroup className="align-items-right position-relative">
                    <BotonPrimaryText
                      title="Click para buscar"
                      type="submit"
                      onClick={() => {
                        handleSearchChange();
                      }}
                    >
                      <i className="fa fa-search me-1"></i>
                      Filtrar
                    </BotonPrimaryText>
                    <BotonDangerText
                      title="Click para limpiar el filtro"
                      type="button"
                      onClick={() => {
                        setDateRangeStart(null);
                        setDateRangeEnd(null);
                        setFilterParams({
                          search: "",
                          h_ingreso_before: "",
                          h_ingreso_after: "",
                          h_salida_before: "",
                          h_salida_after: "",
                          h_ingreso: "",
                          h_salida: "",
                        });
                        setFilterApplied(true);
                      }}
                    >
                      <i className="fa fa-times me-1"></i>
                      Limpiar
                    </BotonDangerText>
                    <BotonPrimaryText
                      title="Click para exportar"
                      onClick={() => {
                        handleExport();
                      }}
                    >
                      <i className="fa fa-download me-1"></i>
                      Exportar
                    </BotonPrimaryText>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              setLoading={setLoading}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              sortField={sortField}
              order={order}
              page={page}
              dataPaginated={dataPaginated}
            />
          )}
        </div>
      </div>

      <div>
        <FacturaParking
          isOpen={showModalFacturacion}
          onClose={() => setShowModalFacturacion(false)}
          URL={showModalReciboUrl}
          dataFromPatch={showModalReciboData}
        />
      </div>

      <ModalCambiarTextoFactura
        showModal={showModalCambiarTextoFactrura}
        onClose={handleCloseCambiarTextoFactrura}
      />

      <Comentarios
        showSidebarComments={showSidebarComments}
        setShowSidebarComments={setShowSidebarComments}
        // comentarios={selectedComentarios}
        urlComentarios={urlComentarios}
        urlComentariosParams={urlComentariosParams}
        urlPost={urlComentarios}
        selectedPrePostData={selectedPrePostData}
        handleNewComment={handleNewComment}
      />
    </>
  );
};

export default FacturacionVehicular;
