import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import { Link } from "react-router-dom";
import { Badge, ButtonGroup } from 'react-bootstrap';

import noImagen from '../../../../images/no-image.jpg';
import { LinkPrimary, CitoPlusLinkIconPrimary, CitoPlusLinkIconDanger } from '../components/CitoPlusLink';
import ModalVehiculoForm from './ModalVehiculoForm';
import Swal from 'sweetalert2';


export const Vehiculos = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const URL = '/api/v3/parking/vehiculos/';
  const titleCard = "Vehiculos"
  const activeMenu = "Autorizaciones"
  const motherMenu = "Vehiculos"

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    {
      id: 'foto',
      Header: 'foto',
      accessor: 'foto',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Foto</div>
      ),
      Cell: ({ value }) => (
        value === null ? (
          <img
            src={noImagen}
            alt=""
            className="me-3 rounded"
            width={75}
          />
        ) : (
          <a href={value} data-lightbox="image">
            <img
              src={value}
              alt=""
              className="me-3 rounded"
              width={75}
              onError={e => {
                e.target.onerror = null;
                e.target.src = noImagen;
              }}
            />
          </a>
        )
      ),
    },
    {
      id: 'detalles',
      accessor: 'detalles',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Detalles</div>
      ),
      accessor: row => {
        return (
          <div className="pt-4 left-align-cell">
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Placa:</h5>
              <p className='paragraph'>{row.placa}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Creado:</h5>
              <p className='paragraph'>{row.created_at}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Tipo:</h5>
              <p className='paragraph'>{row.tipo_vehiculo}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Inmueble:</h5>
              <p className='paragraph'>{row.vivienda_numero}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: 'plaza',
      Header: 'Plaza',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => (
        <div className="pt-4 left-align-cell">
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Plaza:</h5>
            <p className='paragraph'>{row.no_parqueadero}</p>
          </div>
          <div className="mb-1 d-flex">
            {row.estado ? (
              <div className='badge badge-citoplus-success'>Activo</div>
            ) : (
              <div className='badge badge-citoplus-warning'>Inactivo</div>
            )}
          </div>
        </div>
      ),
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <ButtonGroup aria-label="Botones de acciones">
          <CitoPlusLinkIconPrimary
            title="Editar"
            href="#"
            onClick={() => {
              handleOpenModalFormVehiculo(row.slug, true)
            }}
          >
            <i className="fas fa-pencil-alt"></i>
          </CitoPlusLinkIconPrimary>
          
          <CitoPlusLinkIconDanger
            title="Eliminar"
            href="#"
            onClick={() => {
              handleDeleteVehiculo(row.slug)
            }}
          >
            <i className="fas fa-trash"></i>
          </CitoPlusLinkIconDanger>
        </ButtonGroup>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const handleDeleteVehiculo = (slug) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6', 
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.delete(`${URL}${slug}/`)
          .then(() => {
            Swal.fire(
              '¡Eliminado!',
              'El vehículo ha sido eliminado.',
              'success'
            );
            fetchUrl(currentPage, searchValue);
          })
          .catch((error) => {
            console.error('Error al eliminar:', error);
            Swal.fire(
              'Error',
              'No se pudo eliminar el vehículo',
              'error'
            );
          });
      }
    });
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  // Modal Editar o Crear Vehiculo
  const [showModalForm, setShowModalForm] = useState(false);
  const [selectedVehiculoUUID, setSelectedVehiculoUUID] = useState(null);
  const [editModeForm, setEditModeForm] = useState(false);

  const handleOpenModalFormVehiculo = (selectedVehiculoUUID, editMode) => {
    setShowModalForm(true);
    setSelectedVehiculoUUID(selectedVehiculoUUID);
    setEditModeForm(editMode);
  }

  const handleCloseModalForm = () => {
    setShowModalForm(false);
    setSelectedVehiculoUUID(null);
    setEditModeForm(false);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Vehículos</h4>
        <LinkPrimary
          to={`#`}
          className="ms-auto"
          onClick={handleOpenModalFormVehiculo}
        >
          <i className='fas fa-plus me-2'></i>
          Crear Vehículo
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

      {/* Agregar o Editar Vehiculo */}
      <ModalVehiculoForm
        showModal={showModalForm}
        onClose={handleCloseModalForm}
        updateList={fetchUrl}
        selectedVehiculoUUID={selectedVehiculoUUID}
        editMode={editModeForm}
      />

    </>
  );
};

export default Vehiculos;
