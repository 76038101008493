import React from 'react';

const Loading = ({ text }) => {
  return (
    <div className="position-relative" style={{ minHeight: "200px" }}>
      <div 
        className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        style={{
          zIndex: 1000
        }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
        <p className="mt-3 text-primary fw-semibold">
          {text}
        </p>
      </div>
    </div>
  );
};

export default Loading;
