import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../../services/AxiosInstance';
import { notifyError } from '../components/CitoPlusToastr';
import { handleErrors } from '../components/ErrorHandlingComponent';
import _ from 'lodash';
import { LinkPrimary } from '../components/CitoPlusLink';
import Comentarios from '../components/sidebar_comments';
import { useVigilanteReloadContext } from "../../../../context/VisitantesVigilantesContext";
import noImagen from '../../../../images/no-image.jpg';

import { formatDate } from '../utils';
import DatePickerRanger from '../components/DatePickerRanger';

const Cajas = () => {
  const URL = "/api/v3/vigilancia/cajas/";
  const URL_NOTAS = '/api/v3/vigilancia/minuta-comentarios/';
  const [loading, setLoading] = useState(false);
  const { reloadData, setReloadData } = useVigilanteReloadContext();
  const [minutaData, setMinutaData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [filterDate, setFilterDate] = useState({
    date_after: '',
    date_before: ''
  });
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});
  const [selectedPrePostData, setSelectedPrePostData] = useState({});

  // Dates and filters
  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const [dateRangeEnd, setDateRangeEnd] = useState([null, null]);
  const [startDateEnd, endDateEnd] = dateRangeEnd;

  useEffect(() => {
    fetchCajas();
  }, []);

  useEffect(() => {
    if (reloadData.minuta) {
      fetchCajas();
      setReloadData(prev => ({ ...prev, minuta: false }));
    }
  }, [reloadData.minuta]);

  const fetchCajas = async (loadMore = false) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL, {
        params: {
          page: loadMore ? page + 1 : 1,
          search: filterText,
          ...filterDate
        }
      });
      setMinutaData(prevData => loadMore ? [...prevData, ...response.data.results] : response.data.results);
      if (loadMore) setPage(page + 1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchCajas();
  };

  const handleLoadMore = () => {
    fetchCajas(true);
  };

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      minuta: slug,
      nota: '',
      foto: null
    });
    setUrlComentariosParams({
      minuta_uuid: slug,
      page_size: 100
    });
    setShowSidebarComments(true);
  };

  const handleNewComment = () => {
    fetchCajas();
  };

  const renderCajaCard = (item, index) => (
    <div className="col-xl-6" key={index}>
      <div className={`card ${!item.close_at ? 'caja-card-active' : ''}`}>
        <div className="card-body">
          <h4 className="fs-20">{_.startCase(_.toLower(item.asunto))}</h4>
          
          {/* Información de fechas */}
          <div className="d-flex flex-wrap mb-3">
            <div className="me-4">
              <strong>Abierta:</strong> {item.open_at}
            </div>
            <div>
              <strong>Cerrada:</strong> {item.close_at || 'Abierta'}
            </div>
          </div>
          
          <div className="mt-2">
            <strong>Total:</strong> {item.total ? item.total : 0}
          </div>
        </div>
        
        <div 
          className="card-footer d-flex justify-content-between align-items-center"
          style={{ backgroundColor: _.replace(item.prioridad, /-/g, '') }}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Cierre de cajas vehicular</h4>
      </div>
      <div className="row mb-4">
        <div className="col-xl-12">
          <form className="row" onSubmit={handleSearch}>
            <div className="col-md-4">
              <DatePickerRanger
                startDate={startDateStart}
                endDate={endDateStart}
                onChange={(update) => {
                  setDateRangeStart(update);
                  setFilterDate({
                    date_after: update[0] ? formatDate(update[0]) : '',
                    date_before: update[1] ? formatDate(update[1]) : '',
                  });
                }}
                placeholderText="Fechas"
                withPortal
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary w-100">Buscar</button>
            </div>
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-danger w-100"
                onClick={() => {
                  // Reset filter states
                  setFilterText('');
                  setFilterDate({
                    date_after: '',
                    date_before: ''
                  });
                  // Reset DatePicker values
                  setDateRangeStart([null, null]);
                  setDateRangeEnd([null, null]);
                  // Fetch data with reset filters
                  fetchCajas();
                }}
              >
                Limpiar Filtros
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row g-3">
            {minutaData.map(renderCajaCard)}
          </div>
          
          {/* Loader y botón de cargar más */}
          {loading ? (
            <div className="text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
            </div>
          ) : (
            <button 
              className="btn btn-outline-primary w-100 mt-4" 
              onClick={handleLoadMore}
            >
              <i className="fas fa-arrow-down me-2"></i> Cargar más...
            </button>
          )}
        </div>
      </div>

      <Comentarios
        showSidebarComments={showSidebarComments}
        setShowSidebarComments={setShowSidebarComments}
        urlComentarios={urlComentarios}
        urlComentariosParams={urlComentariosParams}
        urlPost={urlComentarios}
        selectedPrePostData={selectedPrePostData}
        handleNewComment={handleNewComment}
      />
    </>
  );
};

export default Cajas;
