export const MenuListVigilante = [
    // Inicio
    {
      title: 'Inicio',
      iconStyle: <i className="fas fa-home"></i>,
      to: 'dashboard',
    },
    {
      title: 'Cajas',
      classChange: 'mm-collapse',
      iconStyle: <i className="fas fa-cash-register"></i>,
      content: [
        {
          title: 'Cierre vehicular',
          to: 'cajas'
        },
      ]
    },
    {
      title: "Visitantes vehicular",
      iconStyle: <i className="fas fa-car"></i>,
      to: 'visitantes-vehiculares',
    },
    {
      title: "Visitantes peatonal",
      iconStyle: <i className="fas fa-walking"></i>,
      to: 'visitantes-peatonales',
    },
    {
      title: "Correspondencia",
      iconStyle: <i className="fas fa-envelope"></i>,
      to: 'correspondencia',
    },
    {
      title: "Autorizaciones",
      iconStyle: <i className="fas fa-clipboard-check"></i>,
      to: 'autorizaciones',
    },
    {
      title: "Minuta",
      iconStyle: <i className="fas fa-book"></i>,
      to: 'minuta',
    },
  ]
