import React, { useState } from 'react';
import axiosInstance from 'services/AxiosInstance';
import { toast } from 'react-toastify';
import { SplitButton, Dropdown } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { set } from 'lodash';

const ExportButton = ({ url, nombreArchivo = "" }) => {

  const [loading, setLoading] = useState(false);
  const [textLoading, setTextLoading] = useState('Calculando progreso...');
  const [btnVariant, setBtnVariant] = useState('primary');
  const [format, setFormat] = useState('csv');

  const uniqueId = uuidv4();

  const handleSelect = (eventKey) => {
    setFormat(eventKey);
  };

  const handleExport = () => {
    const URL = `${url}?format-type=${format}`;

    setLoading(true);
    setBtnVariant('info');
    toast.info('Exportando archivo...');

    axiosInstance.get(
      URL, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {

        // No podemos acceder a responseText cuando responseType es 'blob'
        // En su lugar usamos el progreso de la descarga
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        
        setTextLoading(`Descargando archivo... ${percentCompleted}%`);
        setBtnVariant('success');
        
        if (percentCompleted % 25 === 0) { // Mostrar toast cada 25%
          toast.info(`Progreso: ${percentCompleted}%`);
        }
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: format === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv'
      }));
      const link = document.createElement('a');
      link.href = url;
      if (format === 'csv') {
        link.setAttribute('download', `${nombreArchivo}-${uniqueId}.csv`);
      } else {
        link.setAttribute('download', `${nombreArchivo}-${uniqueId}.xlsx`);
      }
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.success('Exportación completada!');
    }).catch((error) => {
      toast.error('Error al exportar los datos.');
    }).finally(() => {
      setLoading(false);
    });

  };

  return (
    <SplitButton
      size="xs"
      variant={loading ? btnVariant : 'primary'}
      id="dropdown-basic-button"
      title={
        loading ? (
          <span>
            <i className="fa fa-spinner fa-spin"></i> {textLoading}
          </span>
        ) : (
          <span>
            <i className="fa fa-download"></i> Exportar a {format.toUpperCase()}
          </span>
        )
      }
      onSelect={handleSelect}
      onClick={() => handleExport(format)}
    >
      <Dropdown.Item eventKey="csv">CSV</Dropdown.Item>
      <Dropdown.Item eventKey="excel">Excel</Dropdown.Item>
    </SplitButton>
  );
};

export default ExportButton;
