import React from 'react';
import '../../../../css/spinner.css';

export const SpinnerCitoPlus = ({ text }) => {
    return (
        <div className="text-center my-4">
          <div className="spinner-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="spinner-text">
            {text}
          </div>
        </div>
      );
};
