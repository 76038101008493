import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileAction,
  updateProfileAction,
} from "../../../../store/actions/PerfilActions";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { ciudades_colombia } from "jsx/components/CitoPlus/data/ciudades";
import { captureConsoleIntegration } from "@sentry/react";

const editarPerfilSchema = Yup.object().shape({
  email_copropiedad: Yup.string().required("Campo obligatorio."),
  nombre_copropiedad: Yup.string().required("Campo obligatorio."),
  identificacion_copropiedad: Yup.string().required("Campo obligatorio."),
  ciudad: Yup.string().required("Campo obligatorio."),
  direccion: Yup.string().required("Campo obligatorio."),
  telefono_fijo: Yup.string().required("Campo obligatorio."),
  telefono_celular: Yup.string().required("Campo obligatorio."),
  nombre_alternativo: Yup.string().required("Campo obligatorio."),
  telefono_fijo_alternativo: Yup.string().required("Campo obligatorio."),
  telefono_celular_alternativo: Yup.string().required("Campo obligatorio."),
  email_alternativo: Yup.string().required("Campo obligatorio."),
  logo: Yup.string().nullable(),
  social_facebook: Yup.string(),
  social_twitter: Yup.string(),
  social_instagram: Yup.string(),
  social_youtube: Yup.string()
});

const EditarPerfil = () => {

  const [logoChange, setLogoChange] = useState(false);

  const titleCard = "Editar Perfil";
  const activeMenu = "Editar Perfil";
  const motherMenu = "Configuracion";

  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (!profileDetails) {
        return;
      }

      const postDataJson = {
        user: {
          first_name: values.nombre_representante_legal,
          email: values.email_copropiedad
        },
        unidad: values.nombre_copropiedad,
        nit_cc: values.identificacion_copropiedad,
        pais: values.pais,
        ciudad: values.ciudad,
        direccion: values.direccion,
        telefono_fijo: values.telefono_fijo,
        telefono_celular: values.telefono_celular,
        cuadrante: values.telefono_cuadrante,
        alt_nombre: values.nombre_alternativo,
        alt_fijo: values.telefono_fijo_alternativo,
        alt_celular: values.telefono_celular_alternativo,
        alt_email: values.email_alternativo,
        facebook: values.social_facebook,
        twitter: values.social_twitter,
        instagram: values.social_instagram,
        youtube: values.social_youtube,
      };

      if (logoChange && values.logo) {
        postDataJson.logo = values.logo;
      }

      await dispatch(updateProfileAction(postDataJson));
      setSubmitting(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setSubmitting(false);
    }
  };

  const initialValues = {
    nombre_representante_legal: profileDetails?.user?.first_name || "",
    email_copropiedad: profileDetails?.user?.email || "",
    nombre_copropiedad: profileDetails?.unidad || "",
    identificacion_copropiedad: profileDetails?.nit_cc || "",
    pais: profileDetails?.pais || "",
    ciudad: profileDetails?.ciudad || "",
    direccion: profileDetails?.direccion || "",
    telefono_fijo: profileDetails?.telefono_fijo || "",
    telefono_celular: profileDetails?.telefono_celular || "",
    telefono_cuadrante: profileDetails?.cuadrante || "",
    nombre_alternativo: profileDetails?.alt_nombre || "",
    telefono_fijo_alternativo: profileDetails?.alt_fijo || "",
    telefono_celular_alternativo: profileDetails?.alt_celular || "",
    email_alternativo: profileDetails?.alt_email || "",
    logo: profileDetails?.logo || null,
    social_facebook: profileDetails?.facebook || "",
    social_twitter: profileDetails?.twitter || "",
    social_instagram: profileDetails?.instagram || "",
    social_youtube: profileDetails?.youtube || ""
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{activeMenu}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={editarPerfilSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <form
                      className="form-valide"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <fieldset>
                          <legend className="w-auto">Información Principal</legend>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-4 col-form-label" htmlFor="nombre_representante_legal">
                              Nombre Representante
                              <span className="text-danger">*</span>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="nombre_representante_legal"
                                name="nombre_representante_legal"
                                value={values.nombre_representante_legal}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.nombre_representante_legal}
                                </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-4 col-form-label" htmlFor="email_copropiedad">
                              Email Copropiedad
                              <span className="text-danger">*</span>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="email_copropiedad"
                                name="email_copropiedad"
                                value={values.email_copropiedad}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.email_copropiedad}
                                </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-4 col-form-label" htmlFor="nombre_copropiedad">
                              Nombre Copropiedad
                              <span className="text-danger">*</span>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="nombre_copropiedad"
                                name="nombre_copropiedad"
                                value={values.nombre_copropiedad}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.nombre_copropiedad}
                                </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-4 col-form-label" htmlFor="identificacion_copropiedad">
                              NIT
                              <span className="text-danger">*</span>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control bg-light cursor-not-allowed"
                                id="identificacion_copropiedad"
                                name="identificacion_copropiedad"
                                value={values.identificacion_copropiedad}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                              />
                              <small>El NIT no puede ser modificado</small>
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.identificacion_copropiedad}
                                </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend className="w-auto">Información Adicional</legend>
                          <div className="col-xl-6">
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="pais">
                                Pais
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pais"
                                  name="pais"
                                  value={values.pais}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.pais}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="ciudad">
                                Ciudad
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ciudad"
                                  name="ciudad"
                                  value={values.ciudad}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.ciudad}
                                </div>                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Direccion
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="direccion"
                                  name="direccion"
                                  value={values.direccion}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.direccion}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Telefono Fijo
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_fijo"
                                  name="telefono_fijo"
                                  value={values.telefono_fijo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_fijo}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Telefono Celular
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_celular"
                                  name="telefono_celular"
                                  value={values.telefono_celular}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_celular}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Telefono Cuadrante
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_cuadrante"
                                  name="telefono_cuadrante"
                                  value={values.telefono_cuadrante}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_cuadrante}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <div className="col-xl-6">
                            <legend className="w-auto">Contacto presidente de consejo</legend>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Nombre Alternativo
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nombre_alternativo"
                                  name="nombre_alternativo"
                                  value={values.nombre_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.nombre_alternativo}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Telefono Fijo Alternativo
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_fijo_alternativo"
                                  name="telefono_fijo_alternativo"
                                  value={values.telefono_fijo_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_fijo_alternativo}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Telefono Celular Alternativo
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_celular_alternativo"
                                  name="telefono_celular_alternativo"
                                  value={values.telefono_celular_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_celular_alternativo}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Email Alternativo
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email_alternativo"
                                  name="email_alternativo"
                                  value={values.email_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.email_alternativo}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Logo
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="file"
                                  className="form-file-input form-control"
                                  id="logo"
                                  accept="image/*"
                                  name="logo"
                                  onChange={(e) => {
                                    const fileReader = new FileReader();
                                    fileReader.onload = (e) => {
                                      // Directamente establecer el valor del archivo
                                      setFieldValue('logo', fileReader.result);
                                    };
                                    setLogoChange(true);
                                    // setFieldValue('logo', e.currentTarget.files[0]);
                                    fileReader.readAsDataURL(e.currentTarget.files[0]);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.logo}
                                </div>
                                <div className="col-lg-6" style={{ paddingTop: '10px' }}>
                                  {values.logo && (
                                    <div>
                                      <a href={values.logo} data-lightbox="image">
                                        <img
                                          src={values.logo}
                                          alt=""
                                          className="me-3 rounded"
                                          width={85}
                                        />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <div className="col-xl-6">
                            <legend className="w-auto">Información de Redes Sociales</legend>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Facebook
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_facebook"
                                  name="social_facebook"
                                  value={values.social_facebook}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Youtube
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_youtube"
                                  name="social_youtube"
                                  value={values.social_youtube}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Twitter
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_twitter"
                                  name="social_twitter"
                                  value={values.social_twitter}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-lg-4 col-form-label" htmlFor="provincia">
                                Instagram
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_instagram"
                                  name="social_instagram"
                                  value={values.social_instagram}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="card-footer text-end">
                        <button
                          type="submit"
                          className={`btn me-2 btn-citoplus-primary`}
                          disabled={isSubmitting}
                        >
                          Cambiar
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditarPerfil;
