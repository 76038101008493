import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerField = ({ label, id, name, selected, onChange, onBlur, error }) => {
  const handleDateChange = (date) => {
    if (date) {
      // Formatea la fecha como DD/MM/YYYY
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      onChange(formattedDate); // Envía la fecha formateada al padre
    } else {
      onChange(null); // Si no hay fecha seleccionada, envía null
    }
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Convierte DD/MM/YYYY a Date
  };

  return (
    <div className="form-group mb-3 row">
      <div className="col-lg-4 col-form-label" htmlFor={id}>
        {label}
        <span className="text-danger">*</span>
      </div>
      <div className="col-lg-6">
        <DatePicker
          id={id}
          name={name}
          selected={selected ? parseDate(selected) : null} // Convierte el valor seleccionado a Date
          onChange={handleDateChange} // Utiliza la función personalizada para manejar cambios
          onBlur={onBlur}
          className="form-control"
          dateFormat="dd/MM/yyyy"
        />
        {error && (
          <div
            id={`${id}-error`}
            className="invalid-feedback animated fadeInUp"
            style={{ display: "block" }}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePickerField;
