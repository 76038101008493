import React, { useEffect, useState } from "react";
import axiosInstance from "services/AxiosInstance";
import InputField from "./InputField";
import DatePickerField from "./DatePickerField";
import SelectField from "./SelectField";
import * as Yup from "yup";
import { Formik } from "formik";
import { handleErrors } from 'jsx/components/CitoPlus/components/ErrorHandlingComponent';
import { notifySuccess } from "jsx/components/CitoPlus/components/CitoPlusToastr";

const schemaConfiguracionFacturacion = Yup.object().shape({
    prefijo: Yup.string().required("Este campo es requerido"),
    prefijo_factura: Yup.string().required("Este campo es requerido"),
    numero_resolucion: Yup.string().required("Este campo es requerido"),
    fecha_resolucion: Yup.string().required("Este campo es requerido"),
    rango_inicial: Yup.string().required("Este campo es requerido"),
    rango_final: Yup.string().required("Este campo es requerido"),
    ultima_factura_generada: Yup.string().required("Este campo es requerido"),
    fecha_inicial_de_vigencia: Yup.string().required("Este campo es requerido"),
    fecha_final_de_vigencia: Yup.string().required("Este campo es requerido"),
});

const ConfiguracionFacturacion = () => {

    const emptyInitialValues = {
        prefijo: "",
        prefijo_factura: "",
        numero_resolucion: "",
        fecha_resolucion: "",
        rango_inicial: "",
        rango_final: "",
        ultima_factura_generada: "",
        fecha_inicial_de_vigencia: "",
        fecha_final_de_vigencia: "",
    };

    const URL = "/api/v3/usuarios/facturacion-electronica/";
    const URL_POST = `${URL}update-or-create/`

    const [initialValues, setInitialValues] = useState(emptyInitialValues);

    useEffect(() => {
        fetchData();
      }, []);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(URL);
            if (response.data.results.length === 0) {
                setInitialValues(emptyInitialValues);
                return;
            }
            console.log(response.data.results[0]);
            setInitialValues(response.data.results[0]);
        } catch (error) {
            handleErrors(error);
        }
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const formatDateToISO = (date) => {
                if (!date) return null;
                const [day, month, year] = date.split('/').map(Number);
                const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                return formattedDate;
            };

            const formattedValues = {
                ...values,
                fecha_resolucion: formatDateToISO(values.fecha_resolucion),
                fecha_inicial_de_vigencia: formatDateToISO(values.fecha_inicial_de_vigencia),
                fecha_final_de_vigencia: formatDateToISO(values.fecha_final_de_vigencia)
            };

            const response = await axiosInstance.post(URL_POST, formattedValues);
            notifySuccess("Configuración de Facturación Electrónica guardada correctamente");
        } catch (error) {
            handleErrors(error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Configuración de Facturación Electrónica</h4>
                            <p className="card-title-desc">
                                En esta sección puedes configurar los datos necesarios para la facturación electrónica.
                            </p>
                        </div>
                        <div className="card-body">
                            <div className="basic-form">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={schemaConfiguracionFacturacion}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <InputField
                                                label="Prefijo"
                                                id="prefijo"
                                                name="prefijo"
                                                value={values.prefijo}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.prefijo && touched.prefijo ? errors.prefijo : null}
                                            />
                                            <InputField
                                                label="Prefijo Factura"
                                                id="prefijo_factura"
                                                name="prefijo_factura"
                                                value={values.prefijo_factura}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.prefijo_factura && touched.prefijo_factura ? errors.prefijo_factura : null}
                                            />
                                            <InputField
                                                label="Número Resolución"
                                                id="numero_resolucion"
                                                name="numero_resolucion"
                                                value={values.numero_resolucion}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.numero_resolucion && touched.numero_resolucion ? errors.numero_resolucion : null}
                                            />
                                            <DatePickerField
                                                label="Fecha de Resolucion"
                                                id="fecha_resolucion"
                                                name="fecha_inicial_de_vigencia"
                                                selected={values.fecha_resolucion}
                                                onChange={date => setFieldValue('fecha_resolucion', date  )}
                                                onBlur={handleBlur}
                                                error={errors.fecha_resolucion && touched.fecha_resolucion ? errors.fecha_resolucion : null}
                                            />
                                            <InputField
                                                label="Rango Inicial"
                                                id="rango_inicial"
                                                name="rango_inicial"
                                                value={values.rango_inicial}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.rango_inicial && touched.rango_inicial ? errors.rango_inicial : null}
                                            />
                                            <InputField
                                                label="Rango Final"
                                                id="rango_final"
                                                name="rango_final"
                                                value={values.rango_final}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.rango_final && touched.rango_final ? errors.rango_final : null}
                                            />
                                            <InputField
                                                label="Última Factura Generada"
                                                id="ultima_factura_generada"
                                                name="ultima_factura_generada"
                                                value={values.ultima_factura_generada}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ultima_factura_generada && touched.ultima_factura_generada ? errors.ultima_factura_generada : null}
                                            />
                                            <DatePickerField
                                                label="Fecha Inicial de Vigencia"
                                                id="fecha_inicial_de_vigencia"
                                                name="fecha_inicial_de_vigencia"
                                                selected={values.fecha_inicial_de_vigencia}
                                                onChange={date => setFieldValue('fecha_inicial_de_vigencia', date)}
                                                onBlur={handleBlur}
                                                error={errors.fecha_inicial_de_vigencia && touched.fecha_inicial_de_vigencia ? errors.fecha_inicial_de_vigencia : null}
                                            />
                                            <DatePickerField
                                                label="Fecha Final de Vigencia"
                                                id="fecha_final_de_vigencia"
                                                name="fecha_final_de_vigencia"
                                                selected={values.fecha_final_de_vigencia}
                                                onChange={date => setFieldValue('fecha_final_de_vigencia', date)}
                                                onBlur={handleBlur}
                                                error={errors.fecha_final_de_vigencia && touched.fecha_final_de_vigencia ? errors.fecha_final_de_vigencia : null}
                                            />
                                            <SelectField
                                            label="Ciudad"
                                            id="ciudad"
                                            name="ciudad"
                                            value={values.ciudad}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.ciudad && touched.ciudad ? errors.ciudad : null}
                                            />
                                            <div className="card-footer text-end">
                                                <button
                                                    type="submit"
                                                    className={`btn me-2 btn-citoplus-primary`}
                                                    disabled={isSubmitting}
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ConfiguracionFacturacion;
