import React from 'react';

const InputField = ({ label, id, name, value, onChange, onBlur, error }) => {
  return (
    <div className="form-group mb-3 row">
      <div className="col-lg-4 col-form-label" htmlFor={id}>
        {label}
        <span className="text-danger">*</span>
      </div>
      <div className="col-lg-6">
        <input
          type="text"
          className="form-control"
          autoComplete="off"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error && (
          <div
            id={`${id}-error`}
            className="invalid-feedback animated fadeInUp"
            style={{ display: "block" }}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
