import React from 'react';
import Statistics from './Statistics';
import CardsAdministradores from './CardsDashboardAdministrador';
import MyProfile from './MyProfile';
import CustomBanner from './CustomBanner';

const Home = () => {
	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="">
            <div className="col-xl-12 pt-3">
              <CustomBanner />
            </div>
						<div className="col-xl-12">
              <MyProfile />
            </div>
						<div className="col-xl-12 pt-4">
              <div className="row">
                <Statistics />
              </div>
            </div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;
