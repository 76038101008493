import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Card, Col, Row, Button } from 'react-bootstrap';
import noImagen from '../../../../images/no-image.jpg';
import { LinkPrimary, LinkSecondary } from '../components/CitoPlusLink';
import axiosInstance from '../../../../services/AxiosInstance';
import { useVigilanteReloadContext } from "../../../../context/VisitantesVigilantesContext";
import { notifyError, notifySuccess } from '../components/CitoPlusToastr';
import registro_visitas from '../../../../images/registro_visitante_transparente.png';
import Comentarios from '../components/sidebar_comments';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BotonPrimary } from 'jsx/components/CitoPlus/components/BotonesCustom';
import { getDefaultParams } from 'services/DefaultParamets';
import Loading from 'jsx/components/CitoPlus/components/loading';
import { SpinnerCitoPlus } from 'jsx/components/CitoPlus/components/loadingLogo';
import _ from 'lodash';

const VisitantesPeatonales = () => {
  const { reloadData, setReloadData } = useVigilanteReloadContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const URL = '/api/v3/visitantes/ingreso-peatonal/sin_salida/';
  const URL_NOTAS = '/api/v3/vigilancia/visitantes-comentarios/';
  const [loadingRows, setLoadingRows] = useState({});
  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // Comentarios
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [selectedPrePostData, setSelectedPrePostData] = useState({});
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});

  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;

    if (pageIndex === 1) {
      setLoading(true);
    }

    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        return;
      }
      const response = await axiosInstance.get(URL, {
        params: {
          ...defaultParams,
          page: pageIndex,
          search: search,
          ordering: ordering
        }
      });
      if (pageIndex === 1) {
        setData(response.data.results);
      } else {
        setData(prevData => [...data, ...response.data.results]);
      }
      setHasNextPage(response.data.next !== null);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setTotalRegistros(response.data.count);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(debounce((pageIndex, search) => {
    fetchUrl(pageIndex, search);
  }, 500), []);

  useEffect(() => {
    if (reloadData.visitantes) {
      fetchUrl();
      setReloadData(prev => ({ ...prev, visitantes: false }));
    }
  }, [reloadData.visitantes]);

  const handleSalidaVisitante = (slug) => async () => {
    try {
      setLoadingRows(prev => ({ ...prev, [slug]: true }));
      await axiosInstance.patch(
        `/api/v3/visitantes/ingreso-peatonal/${slug}/`,
        {
            'ingreso': false ,
            'h_salida': new Date().toISOString()
        }
      );
      fetchUrl();
      notifySuccess('✔️ La salida fue exitosa!');
    } catch (error) {
      notifyError('❌ Hubo un error al procesar la salida del visitante!');
    } finally {
      setLoadingRows(prev => ({ ...prev, [slug]: false }));
    }
  };

  
  const handleNewComment = (e) => {
    // Recargar la lista despues del comentario
    fetchUrl();
  }
 
 
  const handleLoadMore = () => {
    setLoadingMore(true);
    if (hasNextPage) {
      fetchUrl(currentPage + 1);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      visitante: slug,
      nota: ''
    });
    setUrlComentariosParams({
      visitante__slug: slug,
      page_size: 100
    });
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  };

  return (
    <>
      <div className='d-flex align-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>
          Visitantes Peatonales
          {loading ? (
            <small className="text-muted ms-2">(0 registros)</small>
          ) : (
            <small className="text-muted ms-2">({totalRegistros} registros)</small>
          )}
        </h4>
        <div className='ms-auto'>
          <div className='d-flex align-items-center'>
            <input
                type='text'
                className='form-control form-control-sm me-2'
                placeholder='Buscar...'
                value={searchValue}
                onChange={(e) => handleSearchChange(e.target.value)}
                onKeyPress={handleEnterPress}
              />
            <div className='me-2'>
              <BotonPrimary
                title="Limpiar Filtro"
                onClick={handleClearClick}
              >
                <i class="fa fa-times"></i>
              </BotonPrimary>
            </div>
          </div>
        </div>        
      </div>

      {loading ? (
        <SpinnerCitoPlus text="Cargando Visitantes Peatonales..." />
      ) : data.length === 0 ? (
        <Col xl={12} className="text-center">
          <img src={registro_visitas} alt="No hay visitantes pendientes" className="img-fluid" width={"50%"} />
          <h3 className="mt-3">No hay Visitantes</h3>
        </Col>
      ) : (
        <>
          <Row>
            {data.map((item, index) => (
              <Col xl={6} key={index}>
                <Card>
                  <Card.Body>
                    <h4 className='fs-20'>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Nombre e Identificación </Tooltip>}
                      >
                        <span>
                          {_.startCase(item.nombre)} ({item.identificacion})
                        </span>
                      </OverlayTrigger>
                    </h4>
                    <div className="listline-wrapper mb-4">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Inmueble</Tooltip>}
                      >
                        <span className="item">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24" />
                              <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                              <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                              <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                            </g>
                          </svg>
                          {item.unidad_visita !== null ? (
                            <span>{item.unidad_visita}</span>
                          ) : (
                            "-- Sin Inmueble --"
                          )}
                        </span>
                      </OverlayTrigger>
                      {item.tipo_de_ingreso !== null ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="button-tooltip-2">Tipo de Ingreso</Tooltip>}
                        >
                          <span className="item">
                            <i className="fa fa-person-walking text-primary" aria-hidden="true"></i>
                            {_.toUpper(item.tipo_de_ingreso)}
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Hora de Ingreso</Tooltip>}
                      >
                        <span className="item">
                          <i className="fas fa-clock text-primary"></i>
                          {item.h_ingreso}
                        </span>
                      </OverlayTrigger>
                    </div>
                    <p className="user d-sm-flex d-block pe-md-3 pe-0">
                      {item.foto && (
                        <a href={item.foto} data-lightbox={`image${index}`}>
                          <img
                            src={item.foto || noImagen}
                            alt=""
                            style={{ objectFit: "cover" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImagen;
                            }}
                          />
                        </a>
                      )}
                      <div className='ms-sm-3 m3-md-5 md-0'>
                        <p><span style={{display: 'inline-block', width: '90px', fontWeight: 'bold'}}>Autorizado:</span> {_.startCase(_.capitalize(item.autorizado))}</p>
                        <p><span style={{display: 'inline-block', width: '90px', fontWeight: 'bold'}}>Nota:</span> {_.capitalize(item.nota)}</p>                        
                      </div>
                    </p>
                  </Card.Body>
                  <Card.Footer className='d-flex justify-content-between align-items-center flex-wrap'>
                    <div className='mb-2'>
                    </div>
                    <div className='mb-2'>
                      <LinkSecondary
                        href="#"
                        onClick={() => handleComentariosClick(item.comentarios, item.slug)}
                        title="Comentarios"
                      >
                        <i className="fa-solid fa-comments me-2"></i>
                        ({item.total_comentarios})
                      </LinkSecondary>
                      <LinkPrimary
                        href="#"
                        className={`position-relative ${loadingRows[item.slug] ? 'disabled' : ''}`}
                        onClick={handleSalidaVisitante(item.slug)}
                      >
                        {loadingRows[item.slug] ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <>
                            <i className="fas fa-person-walking me-2"></i>
                            Salida
                          </>
                        )}
                      </LinkPrimary>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-center mt-3">
            {loadingMore ? (
              <SpinnerCitoPlus text="Cargando más visitantes peatonales..." />
            ) : (
              hasNextPage && (
                <Button onClick={handleLoadMore}>Cargar más</Button>
              )
            )}
          </div>
          <Comentarios
            showSidebarComments={showSidebarComments}
            setShowSidebarComments={setShowSidebarComments}
            // comentarios={selectedComentarios}
            urlComentarios={urlComentarios}
            urlComentariosParams={urlComentariosParams}
            urlPost={urlComentarios}
            selectedPrePostData={selectedPrePostData}
            handleNewComment={handleNewComment}
          />
        </>
      )}
    </>
  );
}

export default VisitantesPeatonales;
